<template>
  <div>
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <div v-else class="flex flex-col justify-start gap-10">
      <template>
        <div class="flex justify-start items-center gap-0 mt-5">
          <back-button label="Back" @onClick="$router.back()" variant="secondary" />
          <div class="flex justify-start items-center gap-5 border-l border-jet pl-3">
            <h1 class="text-xl text-left font-extrabold">Compensation Proposal</h1>
            <breadcrumb :items="breadcrumbs" />
          </div>
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start gap-5 px-3">
          <card class="p-3">
            <div class="flex flex-col justify-start gap-3 border border-dashed rounded p-3">
              <h2 class="text-lg font-bold text-darkPurple">Compensation Proposal Details</h2>
              <div class="grid grid-cols-3 gap-4">
                <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                  <p class="text-xs font-black uppercase text-romanSilver">Revision Date</p>
                  <p class="font-semibold text-jet text-base capitalize">
                    <span v-if="requestInfo.effectiveDate">
                      {{ $DATEFORMAT(new Date(requestInfo.effectiveDate),"MMMM dd, yyyy") }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </card>
                <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                  <p class="text-xs font-black uppercase text-romanSilver">Financial Year</p>
                  <p class="font-semibold text-jet text-base capitalize">{{ requestInfo.year }}</p>
                </card>
                <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                  <p class="text-xs font-black uppercase text-romanSilver">Revision Range</p>
                  <p class="font-semibold text-jet text-base capitalize">
                    {{ requestInfo.revisionRangeMinimum }}% - {{ requestInfo.revisionRangeMaximum }}%
                  </p>
                </card>
                <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                  <p class="text-xs font-black uppercase text-romanSilver">Employee Count</p>
                  <p class="font-semibold text-jet text-base capitalize">
                   <span v-if="requestInfo.eligible_employees">
                      {{ requestInfo.eligible_employees.employeeIds.length }}
                   </span>
                  </p>
                </card>
                <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                  <p class="text-xs font-black uppercase text-romanSilver">Cycle Name</p>
                  <p class="font-semibold text-jet text-base capitalize">{{ requestInfo.cycle.cycleName }}</p>
                </card>
                <card class="w-full flex flex-col justify-start items-start gap-1 p-3">
                  <p class="text-xs font-black uppercase text-romanSilver">Cycle Period</p>
                  <p class="font-semibold text-jet text-base capitalize" v-if="requestInfo.cycle">
                    {{ $DATEFORMAT(new Date(requestInfo.cycle.startDate),"MMM dd, yyyy") }} -
                    {{ $DATEFORMAT(new Date(requestInfo.cycle.endDate),"MMM dd, yyyy") }}
                  </p>
                </card>
              </div>
            </div>
          </card>
          <!-- <card class="py-3 px-3 rounded">
            <Table
              class="overflow-auto rounded"
              :headers="headers"
              :items="employeeReviews"
              :has-number="true"
              style="max-height:421px"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.employee" class="font-normal text-sm">
                  <div class="flex flex-col justify-start items-start">
                    <span class="text-darkPurple">
                      {{ item.data.employee.fname }}  {{ item.data.employee.lname }}
                    </span>
                    <span class="text-romanSilver">
                      {{ item.data.employee.designation }}
                    </span>
                  </div>
                </div>
                <div v-if="item.serviceYears" class="font-normal text-sm">
                  <span class="text-darkPurple" v-if="(Number(item.data.serviceYears))">
                    {{ item.data.serviceYears }} Yrs
                  </span>
                  <span v-else> - </span>
                </div>
                <div v-if="item.rangeMidPoint" class="font-normal text-sm">
                  <span class="text-darkPurple">
                    {{ convertToCurrency(item.data.rangeMidPoint) }}
                  </span>
                </div>
                <div v-if="item.currentGrossPay" class="font-normal text-sm">
                    <span class="text-darkPurple">
                      {{ convertToCurrency(item.data.currentGrossPay) }}
                    </span>
                </div>
                <div v-if="item.change" class="font-normal text-sm">
                  <span class="text-darkPurple" v-if="item.data.change">
                    {{ item.data.change.toFixed(2) }}%
                  </span>
                  <span v-else>-</span>
                </div>
                <div v-if="item.increase" class="font-normal text-sm">
                  <span class="text-darkPurple">
                    {{ convertToCurrency(item.data.increase) }}
                  </span>
                </div>
                <div v-if="item.newGrossPay" class="font-normal text-sm">
                  <span class="text-darkPurple">
                    {{ convertToCurrency(item.data.newGrossPay) }}
                  </span>
                </div>
              </template>
            </Table>
          </card> -->
          <card class="p-3 pt-7">
            <div class="border-t border-jet">
              <div class="grid grid-cols-2 gap-4 py-5">
                <div class="flex flex-col justify-start gap-2">
                  <p class="text-base font-semibold">Submitted By:</p>
                  <div class="flex flex-row justify-start items-center gap-2">
                    <div>
                      <div v-if="submittedBy.photo" class="flex justify-center items-center w-24 h-28">
                        <img :src="submittedBy.photo" class="w-24 h-28 rounded" alt="profile photo" />
                      </div>
                      <div v-else class="flex justify-center items-center w-24 h-28 rounded border">
                        <span class="text-blueCrayola text-center font-semibold text-4xl">
                          {{ $getInitials(`${submittedBy.fname} ${submittedBy.lname}`) }}
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-col justify-start gap-2">
                      <p class="text-base text-optimaBlack font-bold">
                        {{ submittedBy.fname }} {{ submittedBy.lname }}
                      </p>
                      <p class="text-lg text-blueCrayola font-bold">
                        <span v-if="submittedBy.userDesignation">
                          {{ submittedBy.userDesignation.name }}
                        </span>
                        <span v-else>-</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col justify-start gap-2" v-if="$route.query.slug !== 'history'">
                  <p class="text-base font-semibold">Current Approver:</p>
                  <div class="flex flex-row justify-start items-center gap-2">
                    <div>
                      <div v-if="approverInfo.photo" class="flex justify-center items-center w-24 h-28">
                        <img :src="approverInfo.photo" class="w-24 h-28 rounded" alt="profile photo" />
                      </div>
                      <div v-else class="flex justify-center items-center w-24 h-28 rounded border">
                        <span class="text-blueCrayola text-center font-semibold text-4xl">
                          {{ $getInitials(`${approverInfo.fname} ${approverInfo.lname}`) }}
                        </span>
                      </div>
                    </div>
                    <div class="w-full flex flex-col justify-start gap-2">
                      <p class="text-base text-optimaBlack font-bold">
                        {{ approverInfo.fname }} {{ approverInfo.lname }}
                      </p>
                      <div class="w-full flex flex-col justify-start gap-2">
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Designation:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="approverInfo.userDesignation">{{ approverInfo.userDesignation.name }}</span>
                            <span v-else>---</span>
                          </p>
                        </div>
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Function:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="approverInfo.orgFunction">{{ approverInfo.orgFunction.name }}</span>
                            <span v-else>---</span>
                          </p>
                        </div>
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Office Location:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="approverInfo.employeeOffice">{{ approverInfo.employeeOffice.name }}</span>
                            <span v-else>---</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
          <card class="flex flex-row justify-between items-center gap-5 p-5">
            <div class="w-full flex flex-col justify-start">
              <p class="text-lg font-bold">Approvers ({{ approversList.length }})</p>
              <p class="text-sm">You can quickly approve or dissaprove from here.</p>
              <p class="text-sm">You also see other approvers’ responses and comments within the approval trail.</p>
            </div>
            <div class="w-full flex flex-row justify-between items-center">
              <div class="w-full flex flex-col justify-start gap-2">
                <div class="flex flex-row justify-start items-center gap-2">
                  <p class="font-bold text-darkPurple text-xl">
                    <span v-if="submittedBy.requests">
                      {{ Math.ceil(submittedBy.requests.approvalPercentage) }}%
                    </span>
                    <span v-else>-</span>
                  </p>
                  <p class="text-romanSilver text-sm">Completed</p>
                </div>
                <template v-if="submittedBy.requests">
                  <ProgressBar :value="submittedBy.requests.approvalPercentage" />
                </template>
                <p class="font-semibold text-xs text-romanSilver uppercase">
                  <span v-if="submittedBy.requests">
                    {{ submittedBy.requests.approversLeft }} Approvers Left
                  </span>
                  <span v-else>-</span>
                </p>
              </div>
              <div class="w-full flex justify-end">
                <Button
                  label="See Comments"
                  variant="secondary"
                  @onClick="isOpen = true"
                  class="button-class btn-border"
                />
              </div>
            </div>
          </card>
          <template v-if="!$store.getters.hasSubscriptionExpired()">
            <div v-if="approverInfo.userId === $AuthUser.id && $route.query.slug !== 'history'">
              <CTextarea
                placeholder="Insert Comment Here"
                :row="8"
                :height="90"
                :col="130"
                v-model="comment"
              />
            </div>
            <div v-if="approverInfo.userId === $AuthUser.id && $route.query.slug !== 'history'">
              <div class="flex flex-row justify-start items-center gap-5">
                <Button
                  label="Approve"
                  variant="primary btn-bg"
                  @onClick="onApproveRequest"
                  :disabled="isSubmitting"
                  class="button-class"
                />
                <Button
                  label="Disapprove"
                  variant="secondary"
                  @onClick="onDisapproveRequest"
                  :disabled="isSubmitting"
                  class="button-class btn-border"
                />
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <template>
      <RightSideBar
        v-if="isOpen"
        @close="isOpen = false"
        close-button
      >
        <template v-slot:title>
          <p class="text-darkPurple">Approvers ({{ approversList.length }})</p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple pb-4">
            See other approvers comments to this request.
          </p>
        </template>
        <div>
          <div v-for="approver in approversList" :key="approver.userId">
            <ApproversCard
              class="flex justify-start gap-2 bg-white pt-2 px-3 even:bg-ghostWhite border-l-4"
              :class="{
                'border-mediumSeaGreen': approver.currentApprover === 'hasApproved',
                'border-carrotOrange': approver.currentApprover === 'active',
                'border-jet': approver.currentApprover === 'inactive',
              }"
            >
              <div class="flex flex-row justify-between items-center w-full">
                <div class="flex flex-row justify-start items-start gap-2">
                  <div>
                    <div v-if="approver.photo" class="flex justify-center items-center w-10 h-10">
                      <img :src="approver.photo" class="w-10 h-10 rounded" alt="profile photo" />
                    </div>
                    <div v-else class="flex justify-center items-center w-10 h-10 rounded border">
                      <span class="text-blueCrayola text-center font-semibold text-4xl">
                        {{ $getInitials(`${approver.fname} ${approver.lname}`) }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-start gap-1">
                    <div class="flex flex-col justify-start items-start">
                      <p class="block text-base">{{ approver.fname }} {{ approver.lname }}</p>
                      <p class="text-romanSilver uppercase text-xs block">
                        <span v-if="approver.userDesignation">
                          {{ approver.userDesignation.name }}
                        </span>
                        <span v-else>-</span>
                      </p>
                      <p class="block text-xs">
                        <span v-if="approver.actionTakenAt">
                          {{ $DATEFORMAT(new Date(approver.actionTakenAt), "MMMM dd, yyyy") }}
                        </span>
                        <span v-else>-</span>
                      </p>
                    </div>
                    <template>
                      <div v-if="approver.comment" class="text-sm text-jet break-words">
                        <p class="font-normal text-romanSilver leading-tight">Comment:</p>
                        <p class="leading-tight"> {{ approver.comment }}</p>
                      </div>
                      <p class="leading-tight" v-else>--No Comment--</p>
                    </template>
                  </div>
                </div>
                <Tag
                  :class="{
                    'approved': approver.currentApprover === 'hasApproved',
                    'pending': approver.currentApprover === 'active',
                    'not-started': approver.currentApprover === 'inactive',
                  }"
                >
                  <span v-if="approver.currentApprover === 'hasApproved'">Approved</span>
                  <span v-if="approver.currentApprover === 'active'">Pending</span>
                  <span v-if="approver.currentApprover === 'inactive'">Pending</span>
                </Tag>
              </div>
            </ApproversCard>
          </div>
        </div>
      </RightSideBar>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import Button from "@scelloo/cloudenly-ui/src/components/button"
import Card from "@scelloo/cloudenly-ui/src/components/card"
// import Table from "@scelloo/cloudenly-ui/src/components/table"
import ProgressBar from "@/components/ProgressBar"
import ApproversCard from "@/components/ApproversCard"
import RightSideBar from "@/components/RightSideBar"
import Tag from "@/components/Tag"

export default {
  components: {
    BackButton,
    Breadcrumb,
    Card,
    // Table,
    Button,
    ProgressBar,
    CTextarea,
    Tag,
    ApproversCard,
    RightSideBar,
  },
  data() {
    return {
      isOpen: false,
      isSubmitting: false,
      requestInfo: {},
      employeeReviews: [],
      comment: null,
      breadcrumbs: [
        { disabled: false, text: "Approval", href: "approval", id: "Approval" },
        { disabled: false, text: "My Approvals", href: "my approvals", id: "My Approvals" },
        { disabled: false, text: "Compensation Proposal Details", id: "CompensationProposalDetails" },
      ],
      headers: [
        { title: "employee", value: "employee" },
        { title: "length of service", value: "serviceYears" },
        { title: "range mid-point", value: "rangeMidPoint" },
        { title: "current gross pay", value: "currentGrossPay" },
        { title: "change (%)", value: "change" },
        { title: "change (ngn)", value: "increase" },
        { title: "new gross pay", value: "newGrossPay" },
      ],
    };
  },
  computed: {
    ...mapState('approval',['approversList', 'isLoading']),
    ...mapGetters('approval', ['approverInfo', 'submittedBy'])
  },
  methods: {
    ...mapActions({
      setIsLoading: 'approval/setIsLoading',
      getApprovalProgress: 'approval/getApprovalProgress',
      getMyApprovalRequests: 'approval/getMyApprovalRequests',
    }),
    onApproveRequest() {
      this.isSubmitting = true
      this.onSubmitApprovalRequest({
        hasApproved: true,
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        comment: this.comment,
        requestSlug: this.approverInfo.requestSlug,
        whoToNotify: this.submittedBy.userId,
        orgId: this.$orgId
      })
    },

    onDisapproveRequest() {
      this.isSubmitting = true
      this.onSubmitApprovalRequest({
        hasApproved: false,
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        comment: this.comment,
        requestSlug: this.approverInfo.requestSlug,
        whoToNotify: this.submittedBy.userId,
        orgId: this.$orgId
      })
    },

    onSubmitApprovalRequest(payload){
      this.$_respondApprovalRequest(payload).then(({ data }) => {
        this.$toasted.success(data.message, { duration: 3000 })
        this.$router.back({ name: "MyApprovals" })
        this.isSubmitting = false
      }).catch((error) => {
        this.$toasted.error(error.message, { duration: 3000 })
        this.isSubmitting = false
      })
    },
    async getCompensationProposalById(requestId){
      await this.$_getCompensationProposalById(requestId).then(({ data }) => {
        this.requestInfo = data.proposal
      })
    },
  },
  async created() {
    this.setIsLoading(true)
    await this.getApprovalProgress(this.$route.params.id)
    await this.getMyApprovalRequests({ userId: this.$AuthUser.id }, {params : '', paginate: "?page=1&perPage=1000", module: '&platform=ess'})
    await this.getCompensationProposalById(this.$route.params.id)
    this.setIsLoading(false)
  },
};
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
.text-10{
  font-size: 10px !important;
}
.button-class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.approved {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.disapproved {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.not-started {
  background: #F6F8F9;
  color: #9BA0A7;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
